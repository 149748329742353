#page-header { 
    height: $page-header__height;
    margin-bottom: $page-header__margin;
    border-bottom: 1px solid $seperator-color;
    

    .navbar { 
        height: $page-header__height;
        justify-content: flex-start;

        &-toggler { 
            @include font-size($page-header__toggler-font-size);
            font-weight: $font-weight-light;
            border: 0;
            display: flex;
            align-items: center;
            @include border-radius(4px);
            color: $body-color;

            #{$link-states} { 
                box-shadow: none;
                background: $primary;
                color: $white;
            }

            &-container { 
                flex-basis: calc((100% / 3) - $navbar-toggler-padding-x);
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }

            .navbar-toggler-icon { 
                margin: 0 5px 0 0;
                background: none;
                vertical-align: baseline;
                height: auto;
                width: auto;
            }
        }

        &-brand { 
            padding: 0;
            margin: 0;
            display: flex;
            justify-content: center;
            flex-basis: calc((100% / 3));

            img { 
                max-width: $page-header__logo__max-width;
                @include media-breakpoint-down(md) { 
                    max-width: $page-header__logo__max-width-small;
                }
            }
        }
    }

    .offcanvas { 
        &-header { 
            .navbar-brand { 
                img { 
                    max-width: $page-header__logo__max-width-large;

                    @include media-breakpoint-down(md) { 
                        max-width: $page-header__logo__max-width-small;
                    }
                }
            }
            .btn-close { 
                @include media-breakpoint-up(md) { 
                    margin-right: -6.3rem;
                    color: $page-header__offcanvas__button-color;
                    border: 2px solid $page-header__offcanvas__button-color;
                    background: transparent;
                    opacity: 1;
                    @include border-radius(4px);
                    @include font-size($page-header__close-button-font-size);

                    #{$link-states} { 
                        color: shift-color($page-header__offcanvas__button-color, -25%);
                        border-color: shift-color($page-header__offcanvas__button-color, -25%);
                    }
                }
            }
        }

        &-body { 
            padding-top: 0;
            .navbar-nav { 
                .nav-item { 
                    position: relative;

                    &.active::before { 
                        content: "";
                        width: 3px;
                        height: 50%;
                        position: absolute;
                        background-color: $primary;
                        display: block;
                        left: -10px;
                        top: 50%;
                        transform: translate(0, -50%);
                    }

                    &.disabled { 
                        .nav-link { 
                            opacity: 0.5;
                        }
                    }

                    .nav-link { 
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 1rem 0;
                        @include font-size(1.1rem);
                        color: $black;
                        font-weight: $font-weight-normal;

                        #{$link-states} { 
                            color: shift-color($black, -25%);
                            border-color: shift-color($black, -25%);
                        }
                    }
                }
            }
        }        

        &-backdrop { 
            -webkit-backdrop-filter: blur($page-header__offcanvas__background-blur-amount);
            backdrop-filter: blur($page-header__offcanvas__background-blur-amount);
        }
    }
}