.sidebar {
  padding: 30px;
  @include border-radius($btn-border-radius);

  .offcanvas-body {
    padding: 0;
    overflow: visible;
  }

  &-block {
    margin-bottom: 1rem;

    &.static {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .title {
        @include font-size(1.4rem);
        border-bottom: 1px solid $seperator-color;
        padding-bottom: 10px;
        margin-bottom: 10px;
        display: block;
        width: 100%;
      }

      .content {
        width: 100%;
      }
    }

    &.filters {
      @include media-breakpoint-up(md) { 
        margin-bottom: 3rem; 
      }

      .filter-block {
        @extend .mb-4;

        .input-title {
          display: block;
          width: 100%;
          @include font-size(1.2rem);
          border-bottom: 1px solid $seperator-color;
          padding-bottom: 10px;
          margin-bottom: 10px;
        }
      }
    }
  }
}

#close-sidebar {
  position: fixed;
  right: 1rem;
  top: 1rem;

  @include media-breakpoint-up(md) {
    display: none;
  }
}