.active-filters { 
    @extend .px-2;
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
    
    li { 
        list-style-type: none;
        display: flex;
        margin: 0 1rem 0 0;
        padding: 0;

        .btn { 
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            flex-wrap: nowrap;
            position: relative;
            background-color: $white;
            border-color: $gray-100;
            color: $body-color; 

            #{$link-states} {
                .close { 
                    color: $white;
                    background-color: $primary;
                }
            }

            .label { 
                @extend .mb-2;
                color: color.scale($body-color, $lightness: 40%);
            }

            .value { 
                @include font-size(1rem);
            }

            .close { 
                position: absolute;
                top: 5px;
                right: 8px;
                padding: 4px;
                border-radius: 2px;
            }
        }
    }
}