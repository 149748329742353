.pagination { 
    @extend .px-2;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: nowrap;

    .list { 
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin: 0;
        padding: 0;
        list-style-type: none;

        .item { 
            padding: 0;
            margin: 0 2px 0 0;
            list-style-type: none;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            @include border-radius($border-radius);

            &.active, #{$link-states} { 
                background-color: $primary;

                .link { 
                    color: $white;
                }
            }

            .link { 
                @extend .px-2, .py-1;
                display: block;
                height: 100%;
                width: 100%;
                color: $body-color; 
                text-decoration: none;
            }
        }
    }
}

.results { 
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}