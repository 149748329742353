// .page-object {
//   .thumbnails {
//     @extend .container;
//     width: 100vw;
//     transform: translateX(-50%);
//     margin-left: 50%;

//     .grid {
//       max-height: 100%;
//       max-width: 100%;
//       display: grid;
//       gap: 2px;
//       grid-template-columns: repeat(4, 1fr);
//       grid-template-rows: repeat(2, 50%);
//       grid-template-areas:
//         'main main main main'
//         'small small small small';
//     }

//     .img {
//       display: flex;
//       max-width: 100%;
//       max-height: 100%;

//       &.img-first {
//         grid-area: main;
//         width: 100%;
//       }

//       picture {
//         max-height: 100%;
//         max-width: 100%;
//       }

//       img {
//         max-height: 100%;
//         max-width: 100%;
//         height: 100%;
//       }
//     }
//   }
// }
// @include media-breakpoint-up(md) {
//   .page-object {
//     .thumbnails {
//       max-width: 75vw;
//       width: 75vw;
//       padding: 0 calc($grid-gutter-width * 0.5);

//       .grid {
//         grid-template-columns: 50% repeat(2, 25%);
//         grid-template-rows: repeat(2, 50%);
//         grid-template-areas:
//           'main small small'
//           'main small small';
//       }
//     }
//   }
// }

.thumbnails {
  @include transition($transition-base);
  --image-count: calc(attr(data-image-count) - 1);
  width: 100%;
  display: grid;
  gap: 2px;
  grid-template-columns: 75% 25%;
  grid-template-rows: repeat(var(--image-count), 1fr);
  grid-template-areas:
    'main side'
    'main side'
    'main side'
    'main side';
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: hidden;

  &[data-image-count="1"] {
    grid-template-areas:
      'main';
  }

  &[data-image-count="4"] {
    grid-template-areas:
      'main side'
      'main side'
      'main side';
  }

  .main {
    grid-area: main;
  }

  .link { 
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  picture {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

/**
 * Desktop styling
 **/
@include media-breakpoint-up(md) {

  .thumbnails {
    grid-template-columns: repeat(var(--image-count), calc(100% - var(--image-count)));
    grid-template-rows: repeat(2, 50%);
    grid-template-areas:
      'main main main main'
      'side side side side';

    &[data-image-count="1"] {
      grid-template-areas:
        'main';
    }

    &[data-image-count="4"] {
      grid-template-areas:
        'main main main main'
        'side side side side';
    }
  }
}
