.page-title {
  @extend .col-12;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: 4rem; 
  
  h1 {
    @include font-size(1.7rem);
    text-transform: uppercase;
    color: $primary;
    letter-spacing: 2px;
    margin: 0;
    padding: 0;
  }
}
