@use 'sass:color';

$slider_margin-top: -$block-margin-bottom;
$slider_margin-bottom: $page-header__margin;
$slider_max-height: 738px;
$slider_width: 100%;

$slider-item-overlay_colour: $black;
$slider-item-overlay_opacity: 0.2;

$slider-caption_colour: $white;
$slider-caption_text-shadow-colour: $black;

$slider-control_size: 3rem;
$slider-control_padding: 15px;
$slider-control_icon-size: 40%;
$slider-control_background-color: $white;
$slider-control-active_background-color: $primary;

$slider-indicator_color: $white;
$slider-indicator_size: 12px;
$slider-indicator-active_color: $primary;
$slider-indicator-active_size: 6px;

#slider {
  margin-bottom: $slider_margin-bottom;
  margin-top: $slider_margin-top;
  padding: 0;
  max-height: $slider_max-height;
  width: $slider_width;
  @include slider-height;

  #homepage-slider {
    overflow: hidden;
    border: 0;
    height: 100%;

    #{$link-states} { 
      .carousel-control {
        &-next,
        &-prev {
          opacity: 1;
        }
      }
    }
 
    .carousel-item {
      picture {
        position: relative;
        display: flex !important;
        justify-content: center;
        align-items: center;
        @include slider-height;

        &::after {
          display: block;
          content: '';
          background-color: $slider-item-overlay_colour;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          opacity: $slider-item-overlay_opacity;
        }

        img {
          min-height: 100%;
        }
      }
    }

    .carousel-caption {
      color: $slider-caption_colour;
      bottom: 50%;
      transform: translateY(50%);
      font-weight: bold;
      text-shadow: 1px 1px 3px $slider-caption_text-shadow-colour;

      .display-6 {
        font-weight: bold;
      }
    }

    .carousel-control {
      &-next,
      &-prev {
        opacity: 0;
        width: 5%;
        -webkit-transition: all 250ms ease-in-out;
        -moz-transition: all 250ms ease-in-out;
        -ms-transition: all 250ms ease-in-out;
        -o-transition: all 250ms ease-in-out;
        transition: all 250ms ease-in-out;

        &:hover {
          & > .carousel-control-next-icon,
          & > .carousel-control-prev-icon {
            background-color: $slider-control-active_background-color;
            filter: invert(0);
          }
        }

        & > .carousel-control-next-icon,
        & > .carousel-control-prev-icon {
          background-color: color.invert($slider-control_background-color);
          padding: $slider-control_padding;
          display: block;
          width: $slider-control_size;
          height: $slider-control_size;
          background-size: $slider-control_icon-size $slider-control_icon-size;
          opacity: 1;
          filter: invert(1);
        }
      }
    }

    .carousel-indicators {
      button {
        background-color: $slider-indicator_color;
        width: $slider-indicator_size;
        height: $slider-indicator_size;
        border-radius: 100%;
        opacity: 1;
        position: relative;

        &::before {
          content: '';
          display: none;
          background-color: $slider-indicator-active_color;
          width: $slider-indicator-active_size;
          height: $slider-indicator-active_size;
          border-radius: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        &.active {
          &::before {
            display: block;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  #slider {
    height: 200px;

    #homepage-slider {
      height: 100%;

      .carousel-inner {
        height: 100%;

        .carousel-item {
          height: 100%;

          picture {
            height: 100%;
          }
        }
      }
    }
  }
}
