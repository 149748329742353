$breadcrumbs_width: 100%;
$breadcrumbs_height: 4rem; 
$breadcrumbs_margin-bottom: 0.5rem;
$breadcrumbs_margin-top: -3rem;
$breadcrumbs_font-size: 0.9rem;
$breadcrumbs_color: $primary;
$breadcrumbs_color-states: shift-color($breadcrumbs_color, 50%);
$breadcrumbs_color-active: $gray-600;

#breadcrumbs { 
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: $breadcrumbs_height; 
    width: $breadcrumbs_width;
    margin-top: $breadcrumbs_margin-top;
    margin-bottom: $breadcrumbs_margin-bottom;
    
    font-weight: $font-weight-normal;
    @include font-size($breadcrumbs_font-size);

    .breadcrumb { 
        @extend .col-12;
        margin: 0;

        &-item { 
            padding: 0 5px;
            
            &:first-child { 
                padding-left: 0;
            }

            &.active { 
                color: $breadcrumbs_color-active;
            }

            a { 
                width: 100%;
                display: block;
                color: $breadcrumbs_color;

                #{$link-states} { 
                    color: $breadcrumbs_color-states;
                }
            }
        }

        &-divider { 
            display: block;
            margin: 0 10px;
            color: $breadcrumbs_color;
        }
    }
}