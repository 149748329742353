.services-overview { 
    .list, .item {
        list-style: none;
        margin: 0; 
        padding: 0;
    }

    .list { 
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        width: 100%;
    }

    .item { 
        @extend .mb-4;
        max-width: 32%;
        overflow: hidden;
        border: 0;
        

        &:hover { 
            cursor: pointer;

            .card-img-overlay { 
                transform: translateY(0) !important;
            }
        }

        picture { 
            max-height: 350px;
            width: 100%;
            
            img { 
                width: 100%;
            }
        }

        .card-img-overlay { 
            display: block;
            background-color: rgba(255, 255, 255, 0.75);
            transform: translateY(100%);
            transition: transform .5s cubic-bezier(.165,.84,.44,1),-webkit-transform .5s cubic-bezier(.165,.84,.44,1);
        }

        footer { 
            background-color: $white;
            width: 100%;
            padding: 1rem;
            text-align: center;
            z-index: 10;
        }
    }

    
}