.object {
    &-features { 
        @extend .lists;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
    }

    &-feature { 
        @extend .lists, .mb-4, .pb-3;
        flex-basis: 31%;
        border-style: solid;
        border-color: $seperator-color;
        border-width: 0px;
        border-bottom-width: 1px;

        &:nth-child(3n-1) {
            margin-right: 3%;
            margin-left: 3%;
        }

        &-label { 
            @include font-size($font-size-sm);
            width: 100%;
            display: block;
        }

        &-value { 
            // @include font-size($font-size-lg);
            width: 100%;
            display: block;
        }
    }
}