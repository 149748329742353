$read-more_height: 200px;

.content-read-more {
  @extend .collapse;
  position: relative;

  &:not(.show) {
    &::after {
      @include gradient-y(color.adjust($white, $alpha: -1), $white);
      content: '';
      height: $read-more_height;
      width: 100%;
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    height: $read-more_height;
    display: block;
    overflow: hidden;
  }
}

.read-more {
  display: flex;
  align-items: center;
  justify-content: center;
  color: map.get($brand-colors, 'gvb-blue');
  text-decoration: none;

  &:hover {
    color: $body-color;
  }

  &:before {
    @extend .fa-solid, .fa-plus;
    content: '\2b';
    margin-right: $icon-margin;
  }

  &:after {
    content: 'Meer lezen';
  }

  &:not(.collapsed) {
    &:before {
      content: '\f068';
    }

    &:after {
      content: 'Minder';
    }
  }
}
