.image { 
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    &.image-style-block-align-right {
        justify-content: flex-end;
    }
}

.image-style-align-left {
    float: left;
    margin-right: 1rem;    
}
.image-style-align-right {
    float: right;
    margin-left: 1rem;
}