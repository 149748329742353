@include media-breakpoint-down(md) {
  html:not([data-scroll='0']) {
    #page-header {
      border: 0;
      position: fixed;
      z-index: 1000;
      top: 0;
      left: 0;
      width: 100%;
      background: $white;
      @include box-shadow($box-shadow);
    }
    #page-content {
      margin-top: 80px;
    }
  }
}
