#subscription-form {
  background-color: $off-color;
  color: $gray-700;
  margin-bottom: 0;
  padding: $block-spacing-y $block-spacing-x;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    & > .row {
      max-width: 50%;
    }
  }

  .subscription-form__title {
    font-weight: $font-weight-light;
    text-transform: uppercase;
    margin-bottom: 2.2rem;
    padding-bottom: 2.2rem;
    letter-spacing: 5px;
    display: block;
    border-bottom: 1px solid $gray-400;
    text-align: center;
  }

  .subscription-form__body {
    display: block;
    text-align: center;
    margin-bottom: 1.8rem;
  }

  .subscription-form__form {
    .ff-fieldtype-file_drag_and_drop {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      label {
        order: -1;
      }
    }

    .ff-fieldtype-submit {
      display: flex;
    }

    .submit-align {
      &-right {
        justify-content: flex-end;
      }
      &-center {
        justify-content: center;
      }
      &-left {
        justify-content: flex-start;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  #subscription-form {
    .container {
      & > .row {
        max-width: none;
      }
    }
    
    .subscription-form__form {
      .ff-fieldtype-submit {
        justify-content: center !important;
      }
    }
  }
}
