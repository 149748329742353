/**
 * Overrides file
 * Place any vendor override variables in here
 * Add a comment WHY you did the override as well
 **/

 // Adding new default font
 $font-family-base: 'Open Sans', system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; 

 // Max container width override, made the XXL container bigger (default: 1320px)
 $container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1450px
 );

 // Typography overrides
 $font-weight-base: 300;

 // Off canvas overrides 
 $offcanvas-horizontal-width: 600px;         // Making main menu wider (default: 400px)
 $offcanvas-backdrop-opacity: 1;             // Swapping to RGB using Alpha channel (default: 0.5)
 $offcanvas-backdrop-bg: rgba(0,0,0,0.5);  // Swapping to RGB using Alpha channel (default: 0.5)
 $offcanvas-padding-x: 2rem;                 // Increasing padding (default: 1rem)
 $offcanvas-padding-y: 2rem;                 // Increasing padding (default: 1rem)

 // Navbar overrides
 $navbar-toggler-padding-x: 0.6rem;
 $navbar-toggler-padding-y: 0.6rem;

 // Override primary colour for buttons
 $primary: map-get($brand-colors, 'gvb-green');
 $btn-color: #fff;
 $btn-box-shadow: none;
 $btn-border-width: 0;

 // Enable shadows
 $enable-shadows: true;

 .btn-primary { 
    --bs-btn-color: var(--bs-white) !important; 
 }

 