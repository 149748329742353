.btn {
  &.btn-large {
    width: 100%;
    padding: $btn-padding-x;
  }

  &.btn-gray { 
    background: $off-color;
    color: $body-color;
  }
}
