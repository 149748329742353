$detail_read-more_height: 100px;
.page-object {
  .thumbnails {
    @extend .mb-5;
    width: calc(100vw - 15px);
    height: 400px;
    transform: translateX(-50%);
    margin-left: 50%;
  }

  .object-details {
    @extend .row;

    & > article {
      @extend .col-md-8;

      & > * {
        display: flex;
        width: 100%;
      }
    }

    & > aside {
      @extend .col-md-4;

      & > * {
        @extend .card, .mb-4, .p-4;
        border-color: $seperator-color;

        &.highlight {
          @include box-shadow($box-shadow);
          @include transition($transition-base);
        }

        .title {
          @extend .h5, .border-separation-bottom;
        }

        & > form { 
          display: none;
        }

        form {
          .row {
            display: flex;
            flex-direction: column;

            .col-sm-6 {
              width: 100%;
            }

            label { 
              left: 0.75rem;
            }
          }
        }
      }
    }

    .title {
      @extend .h4;
    }

    .object-title {
      font-weight: 400;
    }

    .title-wrapper {
      @extend .mb-4;
      align-items: center;
      justify-content: flex-start;
    }

    .object-address::before {
      @extend .vr;
      content: '';
      margin: 0 rfs-fluid-value(0.9rem);
    }

    .object-price {
      @extend .h2;
      flex: 1;
      text-align: right;
    }

    .object-features {
      width: 100%;
    }

    .object-usps {
      border-top: 0 !important;
      border-radius: 0;
      flex-direction: column;

      .object-usp {
        @extend .py-1, .mb-2;
        flex-direction: row;
        justify-content: flex-start;
        cursor: default;

        &:last-child {
          margin-bottom: 0;
        }

        &-icon {
          display: none;
        }

        &-label {
          display: block;
          min-width: 40%;
        }
      }
    }

    .object-subscription-form-cta {
      display: block;
    }

    .object-map {
      @extend .border-separation-bottom;

      & > * {
        width: 100% !important;
      }
    }

    .object-description,
    .object-extra {
      @extend .mb-4;

      & > #object-description,
      & > #object-extra {
        @extend .content-read-more;
      }

      flex-direction: column;
    }
  }
}

@include media-breakpoint-down(md) {
  .page-object {
    .thumbnails {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 75% 25%;
      grid-template-areas:
        'main main main main'
        'small small small small';
    }
  }
}

@include media-breakpoint-up(md) {
  .page-object {
    .thumbnails {
      width: 95vw;
      min-width: 100%;
      min-height: 350px;
      grid-template-columns: repeat(
        var(--image-count),
        calc(100% - var(--image-count))
      );
      grid-template-rows: repeat(2, 50%);
      grid-template-areas:
        'main main side side'
        'main main side side';

        &[data-image-count="4"] {
          grid-template-areas:
          'main main side side'
          'main main side side';
        }
    }

    .object-details {
      .object-subscription-form {
        display: block;
      }

      .object-subscription-form-cta {
        display: none;
      }

      .object-usps {
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .page-object {
    .thumbnails {
      min-height: 400px;
    }
  }
}

@include media-breakpoint-up(xxl) {
  .page-object {
    .thumbnails {
      min-height: 500px;
    }
  }
}

@media (min-width: 1600px) {
  .page-object {
    .thumbnails {
      min-height: 600px;
    }
  }
}
