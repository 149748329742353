.layout-2col {
  #page-content {
    @extend .container;

    .sidebar {
      @extend .offcanvas, .offcanvas-start;
      z-index: calc($zindex-modal - 1);

      @include media-breakpoint-up(md) {
        position: static;
        box-shadow: none;
        flex: 0 0 auto;
        width: 25%;
        transform: none;
        border: 0;
        visibility: visible;
        background: $off-color;
        z-index: auto;
      }
    }

    .main-content {
      @extend .col-md-9, .col-12;

      #toggle-sidebar {
        @extend .mb-4;  
      
        @include media-breakpoint-up(md) {
          display: none;
        }
      }
    }
  }
}
