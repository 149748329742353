$media_min-width: 400px;
$media_min-height: $media_min-width;

.disturbance {
  @extend .container, .row;

  & > main { 
    @extend .col-md-7;
  }

  & > aside { 
    @extend .col-md-5;

    iframe { 
      min-width: $media_min-width;
      min-height: $media_min-height;
      width: 100%;
    }
  }
}

@include media-breakpoint-down(sm) {
}
