.price-filter { 
    .filters-price-slider { 
        margin-top: 45px;

        .placeholder-glow { 
            height: 40px;
            display: block;

            .placeholder { 
                height: 25px;
                display: block;
                border-radius: 0;
                width: calc(100% - 19px);
                margin-left: calc(25px / 2);
                position: relative;
                overflow: visible;

                &::before, &::after { 
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0;
                    left: calc(-25px / 2); 
                    background-color: $primary;
                    width: 25px;
                    height: 25px;
                    border-radius: 100%;
                }

                &::after { 
                    left: auto;
                    right: calc(-25px / 2); 
                }
            }
        }

        .irs { 
            & > .irs { 
                &-line { 
                    height: 25px;
                    background-color: $off-color;
                    border-radius: 0;
                    width: calc(100% - 19px);
                    margin-left: calc(25px / 2);
                    position: relative;
                    overflow: visible;
                    top: auto;

                    &::before, &::after { 
                        content: "";
                        display: block;
                        position: absolute;
                        top: 0;
                        left: calc(-25px / 2); 
                        background-color: $off-color;
                        width: 25px;
                        height: 25px;
                        border-radius: 100%;
                    }

                    &::after { 
                        left: auto;
                        right: calc(-25px / 2); 
                    }
                }

                &-min,
                &-max { 
                    background-color: transparent;
                    top: 50%;
                    left: 10px;
                    transform: translate(0, -50%);
                    padding: 0;
                    margin-top: 1px;
                    @include font-size(0.6rem);
                }

                &-max { 
                    left: auto;
                    right: 10px;
                }

                &-from,
                &-to,
                &-single {  
                    top: -40px;
                    padding: 8px 10px;
                    @include font-size(0.8rem);
                    background-color: $primary;
                    margin-left: 3px;

                    &::before { 
                        border-top-color: $primary;
                    }
                }

                &-from::before { 
                    left: var(--irs-from-bubble-arrow-left);
                }
                &-to::before { 
                    left: var(--irs-to-bubble-arrow-left);
                }
            }

            &-bar {
                height: 25px;
                background-color: $primary;   
                top: 0;        
            }

            &-handle { 
                background-color: transparent;
                height: 20px;
                width: 20px;
                top: 2px;

                #{$link-states} { 
                    cursor: pointer;

                    & > i:first-child { 
                        background-color: $primary;
                        border-color: $white;
                    }
                }

                & > i:first-child { 
                    border: 4px solid $off-color;
                    background-color: $gray-400;
                    height: 20px;
                    width: 20px;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    border-radius: 100%;
                    margin: 0 0 0 2px;
                }

                & > i:nth-child(2) { 
                    display: block;
                    background: $primary;
                    width: 25px;
                    height: 25px;
                    -webkit-border-radius: 100%;
                    border-radius: 100%;
                    margin-top: -2px;
                }
            }
        }

        .form-control.filter-field { 
            display: none;
        }
    }
}