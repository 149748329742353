.object {
  &-usps {
    @extend .list-group, .list-group-horizontal;
    width: 100%;
    justify-content: space-between;
  }

  &-usp {
    // @extend .list-group-item;
    border: 0;
    padding: 0;
    margin-right: $list-group-item-padding-x;
    color: $gray-600;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    #{$link-states} { 
      color: $body-color;
      cursor: pointer;
    }

    &-icon { 
      margin-bottom: 0.4rem;
      flex-basis: 100%;
      
    }

    &-label { 
      display: none;
    }
  }
}
