$page-header__height: 80px;
$page-header__margin: $block-margin-bottom;
$page-header__toggler-font-size: 1rem;
$page-header__close-button-font-size: 1.6rem;
$page-header__logo__max-height: 60px;
$page-header__logo__max-width: 215px;
$page-header__logo__max-width-large: 280px;
$page-header__logo__max-height-small: 40px;
$page-header__logo__max-width-small: 190px;
$page-header__border-color: $seperator-color;
$page-header__offcanvas__button-color: $white;
$page-header__offcanvas__button-color-dark: $black;
$page-header__offcanvas__background-blur-amount: 10px;