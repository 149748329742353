.page .page-contact {
    .main-content { 
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        & > .form { 
            width: calc(65% - 3rem); 
            margin-right: 3rem; 
        }

        & > .body { 
            width: 35%;
        }
    }
}