/**
 * Layout variables
 **/

 :root {
    --maxHandleLeft: 50%;
  }

 // Vertical Block spacing
 $block-spacing-y: 6rem; 
 $block-spacing-x: 0; 
 $block-margin-bottom: 3rem;

 // Marign used to space icons and text
 $icon-margin: rfs-fluid-value(0.4rem);
