body { 
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    & > #page-content { 
        flex-grow: 1;
    }
}

.container-large { 
    @include make-container(20px);
    max-width: 85vw;
}