.object-subscription-form,
.freeform-form { 
    label:not(.form-check-label) { 
        display: flex;
        align-items: flex-start;

        &::after { 
            content: "*";
            margin-left: 5px;
            color: $danger;
            font-size: 0.7rem;
        }
    }
}