#gallery { 
    .modal-dialog { 
        .modal-content {
            .modal-header { 
                position: relative;

                .btn-close { 
                    position: absolute;
                    right: 1rem;
                }

                #gallery-page { 
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    a { 
                        @extend .btn, .btn-primary;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        position: relative;
                    }
                }
            }

            .modal-body {                 
                .gallery { 
                    @extend .row, .mb-5;

                    .col { 
                        @extend .col-md-12;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: flex-start;
                    }

                    ul, li {
                        list-style-type: none;
                        padding: 0;
                    }

                    &-title { 
                        @extend .mb-3, .pb-3;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        

                        .badge { 
                            @extend .text-bg-secondary;
                            font-size: $font-size-sm;
                        }
                    }

                    &-list { 
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: flex-start;
                        max-width: 100%;
                        width: 100%;

                        &_item { 
                            @extend .mb-4;
                            display: block;
                            max-width: 100%;
                            width: 100%;

                            .img { 
                                display: block;
                                width: 100%;
                                max-width: 100%;

                                picture { 
                                    display: block;
                                    width: 100%;
                                    max-width: 100%;

                                    img { 
                                        width: 100%;
                                        max-width: 100%;
                                        height: auto;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}