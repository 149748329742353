.disturbance-list {
  @extend .mb-4;

  .items,
  .item {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .items {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .item {
    @extend .card, .mb-4;
    @include transition($transition-base);
    overflow: hidden;
    border-color: $white;
    width: 185px;
    max-width: 185px;
    margin-right: 1rem;
    flex-grow: 1;
    height: 100%;

    &:nth-child(7n) {
      margin-right: 0;
    }

    #{$link-states} {
      border-color: $seperator-color;
      @include box-shadow($box-shadow);
    }

    .disturbance {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: nowrap;
      text-decoration: none;
      color: $body-color;

      .img {
        @include transition($transition-base);

        width: auto;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
      }

      .card-body {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        width: auto;
        margin-top: 0;
        margin-bottom: 0;
        height: 100%;

        & > * {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          width: 100%;
        }
      }

      .card-title {
        @extend .mb-2;
        font-weight: 400;
        text-align: center;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .disturbance-list {
    .item {

      &:nth-child(7n) {
        margin-right: 1rem;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}
