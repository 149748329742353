$mood-images_max-height: 750px;
$mood-images_max-width: 700px;
$mood-images_image-scaling-factor: 1.05;
$mood-images_transition-duration: 350ms;
$mood-images_overlay-padding: 30px;
$mood-images_height: 750px;

#mood-images { 
    margin-bottom: 4rem; 
    padding: 0;

    .mood-image { 
        height:auto;
        max-height: $mood-images_max-height;
        max-width: $mood-images_max-width;
        overflow: hidden;
        border: 0;

        &:hover { 
            .card-img { 
                -moz-transform: scale($mood-images_image-scaling-factor); 
                -webkit-transform: scale($mood-images_image-scaling-factor);
                -o-transform: scale($mood-images_image-scaling-factor);
                -ms-transform: scale($mood-images_image-scaling-factor);
                transform: scale($mood-images_image-scaling-factor);
            }
        }

        .card-img { 
            -webkit-transition: all $mood-images_transition-duration ease-in-out;
            -moz-transition: all $mood-images_transition-duration ease-in-out;
            -ms-transition: all $mood-images_transition-duration ease-in-out; 
            -o-transition: all $mood-images_transition-duration ease-in-out;
            transition: all $mood-images_transition-duration ease-in-out;

            img { 
                max-height: $mood-images_max-height;                
            }
        }

        .card-img-overlay { 
            padding: $mood-images_overlay-padding;

            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+67,0.2+73,0.9+100 */
            background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 67%,rgba(0,0,0,0.2) 73%,rgba(0,0,0,0.9) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        }
    }  
} 