.border-separation { 
    @extend .mb-4, .pb-4;
    border-style: solid;
    border-color: $seperator-color;
    border-width: 0;
}

.border-separation-bottom { 
    @extend .border-separation;
    border-bottom-width: 1px;
}

.border-separation-top { 
    @extend .border-separation;
    border-top-width: 1px;
}

.border-separation-left { 
    @extend .border-separation;
    border-left-width: 1px;
}

.border-separation-right { 
    @extend .border-separation;
    border-right-width: 1px;
}