.object-list {
  @extend .mb-4;

  .items,
  .item {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .item {
    @extend .card, .mb-4;
    @include transition($transition-base);
    overflow: hidden;
    border: 0;

    #{$link-states} {
      @include box-shadow($box-shadow);
    }

    .object {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      flex-wrap: nowrap;
      height: $object-item-height;
      text-decoration: none;
      color: $body-color;
      flex-direction: column;

      // .thumbnails {
      //   @include transition($transition-base);

      //   width: auto;
      //   height: 100%;
      //   max-height: 100%;
      //   max-width: 100%;
      //   display: grid;
      //   gap: 2px;
      //   grid-template-columns: 75% 25%;
      //   grid-template-rows: repeat(3, 1fr);
      //   grid-template-areas:
      //     'main side'
      //     'main side'
      //     'main side';
      //   align-items: flex-start;
      //   justify-content: space-between;
      //   flex-wrap: wrap;
      //   overflow: hidden;
      //   max-width: 500px;

      //   .main {
      //     grid-area: main;
      //   }

      //   picture {
      //     max-height: 100%;
      //     max-width: 100%;
      //     height: 100%;

      //     #{$link-states} {
      //       position: relative;

      //       &::after {
      //         content: 'Bekijk alle fotos';
      //         display: flex;
      //         align-items: center;
      //         justify-content: center;
      //         width: 100%;
      //         height: 100%;
      //         top: 0;
      //         left: 0;
      //         position: absolute;
      //         background-color: rgba(0, 0, 0, 0.3);
      //         text-align: center;
      //         vertical-align: middle;
      //         color: $white;
      //         font-weight: 400;
      //       }
      //     }
      //   }

      //   img {
      //     max-height: 100%;
      //     max-width: 100%;
      //     height: 100%;
      //   }
      // }

      .card-body {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        width: auto;
        margin-top: 0;
        margin-bottom: 0;
        padding: 1rem;
        background-color: $off-color;

        & > * {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          width: 100%;
        }
      }

      .card-title {
        flex-wrap: wrap;
        justify-content: space-between;
      }

      .card-text {
        flex-grow: 1;
        flex-direction: column;
      }

      .card-footer {
        background-color: transparent;
        border: 0;
        padding: 0;
        justify-content: flex-end;
      }

      &-name {
        @extend .h3;

        #{$link-states} {
          color: $black;
          text-decoration: underline;
        }
      }

      &-address {
        margin-bottom: 0.5rem;
        flex-basis: 100%;
      }

      &-price {
        @include font-size(1.5rem);
        font-weight: bold;
        margin: 0 0 1.2rem 0;
      }

      &-description {
        flex-grow: 1;
        display: none;
      }

      &-usps {
        flex-direction: column;

        .object-usp {
          width: 100%;
          align-items: center;
          justify-content: flex-start;
          flex-direction: row;
          margin: 0 0 0.5rem 0;

          &-icon {
            flex-basis: auto;
            margin: 0 1rem 0 0;
          }
        }
      }
    }
  }
}

/**
 * Desktop styling
 **/
@include media-breakpoint-up(md) {
  .object-list {
    .items,
    .item {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

    .item {
      .object {
        flex-direction: row;

        .thumbnails { 
          grid-template-rows: 75% 25%;
        }
        // .thumbnails {
        //   grid-template-columns: repeat(3, 1fr);
        //   grid-template-rows: 75% 25%;
        //   grid-template-areas:
        //     'main main main'
        //     'side side side';
        // }

        .card-title {
          margin-bottom: 0.5rem;
        }

        .card-body {
          padding: 2.5rem;
        }

        &-address { 
          margin-bottom: 1rem;
        }

        &-description {
          display: flex;
        }

        &-price { 
          margin: 1.2rem 0;
        }

        &-usps {
          flex-direction: row;
  
          .object-usp {
            width: auto;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            margin: 0 1rem 0 0;
            max-width: 100px;
            text-align: center;
  
            &-icon {
              margin: 0 0 0.4rem 0;
              flex-basis: 100%;
              flex: 0;
            }
          }
        }
      }
    }
  }
}
