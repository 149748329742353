#banner {
  .banner {
    height: auto;
    position: relative;
    overflow: hidden;

    img {
      max-height: 300px;
    }

    &-content {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      left: 50%;
      top: 50%;
      transform: translate(
        -50%,
        -50%
      );
      max-height: 100px;

      main {
        padding: 15px
          50px;
        display: block;
        background-color: rgba(
          0,
          0,
          0,
          0.6
        );
        color: $white;
        font-weight: $font-weight-medium;
        letter-spacing: 2px;
        @include font-size(
          1.2rem
        );
      }
    }
  }
}
