// Variables for heights
$height-8k: 738px;
$height-5k: 738px;
$height-4k: 738px;
$height-1440p: 738px;
$height-1080p: 732px;
$height-1366x768: 520px;
$height-1280x1024: 487px;
$height-720p: 486px;
$height-480p: 388px;

// Variables for breakpoints (widths)
$breakpoint-8k: 7680px;
$breakpoint-5k: 5120px;
$breakpoint-4k: 3840px;
$breakpoint-1440p: 2560px;
$breakpoint-1080p: 1920px;
$breakpoint-1366x768: 1366px;
$breakpoint-720p: 1280px;
$breakpoint-1280x1024: 1280px;
$breakpoint-480p: 960px;

// Mixin for slider height
@mixin slider-height {
  // 8K to 1440p
  @media (min-width: $breakpoint-1440p) {
    height: $height-8k;
  }

  // 1080p
  @media (min-width: $breakpoint-1080p) and (max-width: ($breakpoint-1440p - 1px)) {
    height: $height-1080p;
  }

  // 1366x768
  @media (min-width: $breakpoint-1366x768) and (max-width: ($breakpoint-1080p - 1px)) {
    height: $height-1366x768;
  }

  // 1280x1024
  @media (min-width: $breakpoint-1280x1024) and (max-width: ($breakpoint-1366x768 - 1px)) {
    height: $height-1280x1024;
  }

  // 720p
  @media (min-width: $breakpoint-720p) and (max-width: ($breakpoint-1280x1024 - 1px)) {
    height: $height-720p;
  }

  // 480p
  @media (min-width: $breakpoint-480p) and (max-width: ($breakpoint-720p - 1px)) {
    height: $height-480p;
  }
}