.freeform-fieldtype-file-dnd.form-floating {
  display: flex;
  flex-direction: column;

  label {
    display: none;
  }

  .form-text {
    order: 10;
  }

  .freeform-file-dnd__input {
    height: auto;
    padding: 1.625rem 0;

    .freeform-file-dnd__placeholder[data-placeholder] {
      @include font-size(0.9rem);
      max-width: 75%;
      margin: 0 auto;
    }
  }
}
