.share-content { 
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    ul, li {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .title { 
        &:not(.-inline) { 
            @extend .border-separation-bottom;
            flex-basis: 100%;
            text-align: center;
        }

        &.-inline { 
            @include font-size($font-size-base);
            margin: 0 $icon-margin 0 0;
        }
    }

    .social-media { 
        display: flex;
        align-items: center;
        justify-content: flex-start;

        li { 
            margin-right: $icon-margin;
        }
    }
}