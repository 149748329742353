$footer__font-size: rfs-fluid-value(0.9rem);
$footer__font-size-title: rfs-fluid-value(1rem);
$footer__font-color: $gray-400;
$footer__heading-color: $white;
$footer__background-color: $gray-800;
$footer__background-color-light: shift-color($gray-800, -5%);
$footer__margin-top: $block-margin-bottom;

#page-footer { 
    color: $footer__font-color;
    background-color: $footer__background-color;
    font-size: $footer__font-size;
    margin-top: $footer__margin-top;

    main, footer { 
        display: block;

        & > * { 
            @extend .container;

            & > * { 
                @extend .row;
            }
        }
    }

    main { 
        padding: 4rem 0;

        .block { 
            @extend .col-md-3;
            margin-bottom: 1.2rem;
        }
    }

    footer { 
        padding: 1.5rem 0;
        background-color: $footer__background-color-light;

        .block { 
            @extend .col-md-6;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            &:last-child { 
                justify-content: flex-end;
            }
        }
    }

    a { 
        color: $footer__font-color;
        text-decoration: none;

        #{$link-states} { 
            color: $footer__heading-color;
            text-decoration: underline;
        }
    }

    h3 { 
        font-size: $footer__font-size;
        text-transform: uppercase;
        letter-spacing: 2px;
        color: $footer__heading-color;
        margin-bottom: 1.5rem;
    }

    strong { 
        font-weight: $font-weight-semibold;
    }

    nav { 
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        &.horizontal { 
            flex-direction: row;

            .link { 
                margin: 0 0.9rem 0 0;

                &:last-child { 
                    margin-right: 0;
                }
            }
        }

        .link { 
            margin-bottom: 0.9rem;
            padding: 0;

            &-icon { 
                margin-right: 0.5rem;
            }
        }
    }    
}

@include media-breakpoint-down(md) { 
    #page-footer {     
        main { 
            padding: 3rem 0;
            
            .block { 
                margin-bottom: 1.5rem;
                align-items: center;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                text-align: center;

                &:last-child { 
                    margin-bottom: 0;
                }
            }
        }
    
        footer {     
            .block { 
                justify-content: center;

                &:last-child { 
                    justify-content: center;
                }
            }
        }

        h3 { 
            margin-bottom: 0.5rem;
        }
    
        nav { 
            align-items: center;

            .link { 
                margin-bottom: 0.3rem;
                    
                &-icon { 
                    display: none;
                }
            }
        }
    }
} 