.freeform-fieldtype-checkbox.switch,  
.freeform-fieldtype-checkboxes.switch { 
    & > div { 
        @extend .form-switch, .form-check;
    }

    .form-check.form-switch { 
        input, label {
            cursor: pointer;
        }
    }
}